import React from "react";
import background from "./assets/background.png";

const ComingSoon = () => {
  return (
    <div className="relative h-screen text-white">
      <img src={background} alt="Background" className="w-full h-full object-cover" />

      <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
        <div className="flex flex-col gap-5 px-4 lg:px-0 lg:w-[700px] w-full">
          <h1 className="lg:text-[57px] md:text-[40px] sm:text-[30px] text-[25px] font-bold">
            Empowering Global Businesses – Stay Tuned
          </h1>
          <p className="lg:text-[24px] text-[18px] ">
            Our Website is Coming Soon...
          </p>
        </div>

        {/* Footer */}
        <div className="absolute bottom-4 md:text-sm text-xs">
          &copy; 2024 Porcious Group. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
