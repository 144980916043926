import Comming_Soon from "./Comming_Soon";

function App() {
  return (
    <>
      <Comming_Soon />
    </>
  );
}

export default App;
